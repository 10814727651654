import React from 'react';
import { Helmet } from 'react-helmet';

const ResumeIiitm = () => {
  const redirectUrl = 'https://drive.google.com/file/d/1enduJ6OR7PZ1jXjrTr96O0SCbmMIcs7q/view';
  return (
    <div>
      <Helmet>
        <meta httpEquiv="refresh" content={`0;url=${redirectUrl}`} />
      </Helmet>
    </div>
  );
};

export default ResumeIiitm;
